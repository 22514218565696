@import url(https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin);
*, ::before, ::after {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-gradient-from-position:  ;

    --tw-gradient-via-position:  ;

    --tw-gradient-to-position:  ;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  ;

    --tw-contain-size:  ;

    --tw-contain-layout:  ;

    --tw-contain-paint:  ;

    --tw-contain-style:  
}

::backdrop {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-gradient-from-position:  ;

    --tw-gradient-via-position:  ;

    --tw-gradient-to-position:  ;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  ;

    --tw-contain-size:  ;

    --tw-contain-layout:  ;

    --tw-contain-paint:  ;

    --tw-contain-style:  
}

.tw-fixed {

    position: fixed
}

.tw-absolute {

    position: absolute
}

.tw-relative {

    position: relative
}

.tw-bottom-0 {

    bottom: 0px
}

.tw-left-0 {

    left: 0px
}

.tw-left-3 {

    left: 0.75rem
}

.tw-left-\[0\.4rem\] {

    left: 0.4rem
}

.tw-right-0 {

    right: 0px
}

.tw-top-0 {

    top: 0px
}

.tw-top-\[5px\] {

    top: 5px
}

.tw-top-full {

    top: 100%
}

.tw-z-10 {

    z-index: 10
}

.tw-z-20 {

    z-index: 20
}

.tw-float-right {

    float: right
}

.tw-m-3 {

    margin: 0.75rem
}

.tw-mx-auto {

    margin-left: auto;

    margin-right: auto
}

.tw-my-0 {

    margin-top: 0px;

    margin-bottom: 0px
}

.\!tw-mr-0 {

    margin-right: 0px !important
}

.\!tw-mt-12 {

    margin-top: 3rem !important
}

.-tw-mr-0\.5 {

    margin-right: -0.125rem
}

.tw-mb-0 {

    margin-bottom: 0px
}

.tw-mb-2 {

    margin-bottom: 0.5rem
}

.tw-mb-4 {

    margin-bottom: 1rem
}

.tw-mb-5 {

    margin-bottom: 1.25rem
}

.tw-mb-6 {

    margin-bottom: 1.5rem
}

.tw-ml-0 {

    margin-left: 0px
}

.tw-ml-3 {

    margin-left: 0.75rem
}

.tw-ml-4 {

    margin-left: 1rem
}

.tw-ml-5 {

    margin-left: 1.25rem
}

.tw-ml-60 {

    margin-left: 15rem
}

.tw-mr-10 {

    margin-right: 2.5rem
}

.tw-mr-2 {

    margin-right: 0.5rem
}

.tw-mt-0 {

    margin-top: 0px
}

.tw-mt-1 {

    margin-top: 0.25rem
}

.tw-mt-10 {

    margin-top: 2.5rem
}

.tw-mt-2 {

    margin-top: 0.5rem
}

.tw-mt-3 {

    margin-top: 0.75rem
}

.tw-mt-4 {

    margin-top: 1rem
}

.tw-mt-6 {

    margin-top: 1.5rem
}

.tw-mt-8 {

    margin-top: 2rem
}

.tw-box-border {

    box-sizing: border-box
}

.tw-block {

    display: block
}

.tw-flex {

    display: flex
}

.tw-inline-flex {

    display: inline-flex
}

.tw-grid {

    display: grid
}

.tw-h-3 {

    height: 0.75rem
}

.tw-h-32 {

    height: 8rem
}

.tw-h-4 {

    height: 1rem
}

.tw-h-5 {

    height: 1.25rem
}

.tw-h-96 {

    height: 24rem
}

.tw-h-fit {

    height: fit-content
}

.tw-h-full {

    height: 100%
}

.tw-w-1\/3 {

    width: 33.333333%
}

.tw-w-10\/12 {

    width: 83.333333%
}

.tw-w-3 {

    width: 0.75rem
}

.tw-w-3\/12 {

    width: 25%
}

.tw-w-32 {

    width: 8rem
}

.tw-w-4 {

    width: 1rem
}

.tw-w-44 {

    width: 11rem
}

.tw-w-45w {

    width: 45%
}

.tw-w-48 {

    width: 12rem
}

.tw-w-5 {

    width: 1.25rem
}

.tw-w-55w {

    width: 55%
}

.tw-w-60 {

    width: 15rem
}

.tw-w-80 {

    width: 20rem
}

.tw-w-\[1px\] {

    width: 1px
}

.tw-w-\[220px\] {

    width: 220px
}

.tw-w-auto {

    width: auto
}

.tw-w-full {

    width: 100%
}

.tw-min-w-max {

    min-width: max-content
}

.tw-max-w-7xl {

    max-width: 80rem
}

.tw-max-w-\[96rem\] {

    max-width: 96rem
}

.tw-flex-shrink {

    flex-shrink: 1
}

.tw-shrink-0 {

    flex-shrink: 0
}

.tw-flex-grow {

    flex-grow: 1
}

.tw-cursor-pointer {

    cursor: pointer
}

.tw-cursor-text {

    cursor: text
}

.tw-list-none {

    list-style-type: none
}

.tw-grid-cols-1 {

    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.tw-flex-row {

    flex-direction: row
}

.tw-flex-col {

    flex-direction: column
}

.tw-flex-wrap {

    flex-wrap: wrap
}

.tw-flex-nowrap {

    flex-wrap: nowrap
}

.tw-content-center {

    align-content: center
}

.tw-items-start {

    align-items: flex-start
}

.tw-items-end {

    align-items: flex-end
}

.tw-items-center {

    align-items: center
}

.tw-items-stretch {

    align-items: stretch
}

.tw-justify-start {

    justify-content: flex-start
}

.tw-justify-end {

    justify-content: flex-end
}

.tw-justify-center {

    justify-content: center
}

.tw-justify-between {

    justify-content: space-between
}

.tw-justify-around {

    justify-content: space-around
}

.tw-gap-1 {

    gap: 0.25rem
}

.tw-gap-2 {

    gap: 0.5rem
}

.tw-gap-3 {

    gap: 0.75rem
}

.tw-gap-4 {

    gap: 1rem
}

.tw-gap-6 {

    gap: 1.5rem
}

.tw-gap-7 {

    gap: 1.75rem
}

.tw-gap-8 {

    gap: 2rem
}

.tw-gap-x-2 {

    column-gap: 0.5rem
}

.tw-gap-x-4 {

    column-gap: 1rem
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(1rem * var(--tw-space-x-reverse));

    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.tw-space-y-7 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse))
}

.tw-self-end {

    align-self: flex-end
}

.tw-overflow-auto {

    overflow: auto
}

.tw-overflow-hidden {

    overflow: hidden
}

.tw-overflow-x-auto {

    overflow-x: auto
}

.tw-overflow-y-auto {

    overflow-y: auto
}

.tw-overflow-y-scroll {

    overflow-y: scroll
}

.tw-truncate {

    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap
}

.tw-rounded-2xl {

    border-radius: 1rem
}

.tw-rounded-3xl {

    border-radius: 1.5rem
}

.tw-rounded-full {

    border-radius: 9999px
}

.tw-rounded-lg {

    border-radius: 0.5rem
}

.tw-rounded-md {

    border-radius: 0.375rem
}

.tw-rounded-xl {

    border-radius: 0.75rem
}

.tw-rounded-r-md {

    border-top-right-radius: 0.375rem;

    border-bottom-right-radius: 0.375rem
}

.tw-border {

    border-width: 1px
}

.tw-border-0 {

    border-width: 0px
}

.tw-border-2 {

    border-width: 2px
}

.tw-border-b-2 {

    border-bottom-width: 2px
}

.tw-border-l-2 {

    border-left-width: 2px
}

.tw-border-t {

    border-top-width: 1px
}

.tw-border-solid {

    border-style: solid
}

.tw-border-none {

    border-style: none
}

.tw-border-black {

    --tw-border-opacity: 1;

    border-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.tw-border-blue-600 {

    --tw-border-opacity: 1;

    border-color: rgb(37 99 235 / var(--tw-border-opacity))
}

.tw-border-gray-200 {

    --tw-border-opacity: 1;

    border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.tw-border-gray-300 {

    --tw-border-opacity: 1;

    border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.tw-border-white {

    --tw-border-opacity: 1;

    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.tw-bg-\[\#2563EB\] {

    --tw-bg-opacity: 1;

    background-color: rgb(37 99 235 / var(--tw-bg-opacity))
}

.tw-bg-black {

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.tw-bg-blue-600 {

    --tw-bg-opacity: 1;

    background-color: rgb(37 99 235 / var(--tw-bg-opacity))
}

.tw-bg-customBlue {

    --tw-bg-opacity: 1;

    background-color: rgb(227 245 255 / var(--tw-bg-opacity))
}

.tw-bg-gray-100 {

    --tw-bg-opacity: 1;

    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.tw-bg-gray-300 {

    --tw-bg-opacity: 1;

    background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}

.tw-bg-gray-50 {

    --tw-bg-opacity: 1;

    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.tw-bg-red-600 {

    --tw-bg-opacity: 1;

    background-color: rgb(220 38 38 / var(--tw-bg-opacity))
}

.tw-bg-transparent {

    background-color: transparent
}

.tw-bg-white {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.tw-object-cover {

    object-fit: cover
}

.tw-p-2 {

    padding: 0.5rem
}

.tw-p-3 {

    padding: 0.75rem
}

.tw-p-4 {

    padding: 1rem
}

.tw-p-5 {

    padding: 1.25rem
}

.tw-p-6 {

    padding: 1.5rem
}

.tw-p-8 {

    padding: 2rem
}

.tw-px-1 {

    padding-left: 0.25rem;

    padding-right: 0.25rem
}

.tw-px-10 {

    padding-left: 2.5rem;

    padding-right: 2.5rem
}

.tw-px-4 {

    padding-left: 1rem;

    padding-right: 1rem
}

.tw-px-5 {

    padding-left: 1.25rem;

    padding-right: 1.25rem
}

.tw-px-6 {

    padding-left: 1.5rem;

    padding-right: 1.5rem
}

.tw-py-1 {

    padding-top: 0.25rem;

    padding-bottom: 0.25rem
}

.tw-py-2 {

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}

.tw-py-2\.5 {

    padding-top: 0.625rem;

    padding-bottom: 0.625rem
}

.tw-py-3 {

    padding-top: 0.75rem;

    padding-bottom: 0.75rem
}

.tw-py-4 {

    padding-top: 1rem;

    padding-bottom: 1rem
}

.tw-py-6 {

    padding-top: 1.5rem;

    padding-bottom: 1.5rem
}

.tw-py-8 {

    padding-top: 2rem;

    padding-bottom: 2rem
}

.tw-pb-10 {

    padding-bottom: 2.5rem
}

.tw-pb-12 {

    padding-bottom: 3rem
}

.tw-pb-2 {

    padding-bottom: 0.5rem
}

.tw-pb-3 {

    padding-bottom: 0.75rem
}

.tw-pb-8 {

    padding-bottom: 2rem
}

.tw-pl-0 {

    padding-left: 0px
}

.tw-pl-3 {

    padding-left: 0.75rem
}

.tw-pl-4 {

    padding-left: 1rem
}

.tw-pl-5 {

    padding-left: 1.25rem
}

.tw-pl-\[0\.1rem\] {

    padding-left: 0.1rem
}

.tw-pl-\[1\.6rem\] {

    padding-left: 1.6rem
}

.tw-pr-10 {

    padding-right: 2.5rem
}

.tw-pr-2 {

    padding-right: 0.5rem
}

.tw-pr-3 {

    padding-right: 0.75rem
}

.tw-pt-0 {

    padding-top: 0px
}

.tw-pt-10 {

    padding-top: 2.5rem
}

.tw-pt-4 {

    padding-top: 1rem
}

.tw-pt-40 {

    padding-top: 10rem
}

.tw-pt-8 {

    padding-top: 2rem
}

.tw-text-left {

    text-align: left
}

.tw-text-center {

    text-align: center
}

.tw-text-right {

    text-align: right
}

.tw-align-middle {

    vertical-align: middle
}

.tw-text-2xl {

    font-size: 1.5rem;

    line-height: 2rem
}

.tw-text-4xl {

    font-size: 2.25rem;

    line-height: 2.5rem
}

.tw-text-5xl {

    font-size: 3rem;

    line-height: 1
}

.tw-text-6xl {

    font-size: 3.75rem;

    line-height: 1
}

.tw-text-7xl {

    font-size: 4.5rem;

    line-height: 1
}

.tw-text-\[12px\] {

    font-size: 12px
}

.tw-text-\[14px\] {

    font-size: 14px
}

.tw-text-\[15px\] {

    font-size: 15px
}

.tw-text-\[18px\] {

    font-size: 18px
}

.tw-text-base {

    font-size: 1rem;

    line-height: 1.5rem
}

.tw-text-lg {

    font-size: 1.125rem;

    line-height: 1.75rem
}

.tw-text-sm {

    font-size: 0.875rem;

    line-height: 1.25rem
}

.tw-text-xl {

    font-size: 1.25rem;

    line-height: 1.75rem
}

.tw-font-bold {

    font-weight: 700
}

.tw-font-extrabold {

    font-weight: 800
}

.tw-font-medium {

    font-weight: 500
}

.tw-font-normal {

    font-weight: 400
}

.tw-font-semibold {

    font-weight: 600
}

.tw-capitalize {

    text-transform: capitalize
}

.tw-leading-4 {

    line-height: 1rem
}

.tw-leading-5 {

    line-height: 1.25rem
}

.tw-leading-7 {

    line-height: 1.75rem
}

.tw-leading-8 {

    line-height: 2rem
}

.tw-text-\[\#000000\] {

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.tw-text-black {

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.tw-text-blue-600 {

    --tw-text-opacity: 1;

    color: rgb(37 99 235 / var(--tw-text-opacity))
}

.tw-text-customBlack {

    --tw-text-opacity: 1;

    color: rgb(77 77 77 / var(--tw-text-opacity))
}

.tw-text-gray-500 {

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.tw-text-gray-600 {

    --tw-text-opacity: 1;

    color: rgb(75 85 99 / var(--tw-text-opacity))
}

.tw-text-gray-700 {

    --tw-text-opacity: 1;

    color: rgb(55 65 81 / var(--tw-text-opacity))
}

.tw-text-gray-900 {

    --tw-text-opacity: 1;

    color: rgb(17 24 39 / var(--tw-text-opacity))
}

.tw-text-white {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.tw-underline {

    text-decoration-line: underline
}

.tw-shadow-lg {

    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tw-shadow-md {

    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tw-outline-none {

    outline: 2px solid transparent;

    outline-offset: 2px
}

.tw-ring-1 {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.tw-ring-inset {

    --tw-ring-inset: inset
}

.tw-ring-gray-300 {

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))
}

.last\:tw-mb-0:last-child {

    margin-bottom: 0px
}

.hover\:tw-border-8:hover {

    border-width: 8px
}

.hover\:tw-border-blue-300:hover {

    --tw-border-opacity: 1;

    border-color: rgb(147 197 253 / var(--tw-border-opacity))
}

.hover\:tw-bg-blue-700:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(29 78 216 / var(--tw-bg-opacity))
}

.hover\:tw-bg-gray-100:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.hover\:tw-bg-gray-400:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}

.hover\:tw-text-gray-900:hover {

    --tw-text-opacity: 1;

    color: rgb(17 24 39 / var(--tw-text-opacity))
}

.hover\:tw-opacity-90:hover {

    opacity: 0.9
}

.focus\:tw-ring-1:focus {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:tw-ring-blue-600:focus {

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity))
}

.disabled\:tw-opacity-50:disabled {

    opacity: 0.5
}

@media (min-width: 640px) {

    .sm\:tw-w-\[calc\(50\%-1rem\)\] {

        width: calc(50% - 1rem)
    }

    .sm\:tw-grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .sm\:tw-text-sm {

        font-size: 0.875rem;

        line-height: 1.25rem
    }

    .sm\:tw-leading-6 {

        line-height: 1.5rem
    }
}

@media (min-width: 768px) {

    .md\:tw--left-23\.5rem {

        left: -23.5rem
    }

    .md\:tw-mt-0 {

        margin-top: 0px
    }

    .md\:tw-w-50w {

        width: 50%
    }

    .md\:tw-w-\[60\%\] {

        width: 60%
    }

    .md\:tw-w-auto {

        width: auto
    }

    .md\:tw-w-fit {

        width: fit-content
    }

    .md\:tw-w-full {

        width: 100%
    }

    .md\:tw-grid-cols-1 {

        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .md\:tw-grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:tw-flex-row {

        flex-direction: row
    }

    .md\:tw-flex-col {

        flex-direction: column
    }

    .md\:tw-gap-3 {

        gap: 0.75rem
    }

    .md\:tw-gap-6 {

        gap: 1.5rem
    }

    .md\:tw-gap-8 {

        gap: 2rem
    }

    .md\:tw-text-left {

        text-align: left
    }

    .md\:tw-text-3xl {

        font-size: 1.875rem;

        line-height: 2.25rem
    }

    .md\:tw-text-\[14px\] {

        font-size: 14px
    }

    .md\:tw-text-\[16px\] {

        font-size: 16px
    }

    .md\:tw-text-\[20px\] {

        font-size: 20px
    }

    .md\:tw-text-base {

        font-size: 1rem;

        line-height: 1.5rem
    }

    .md\:tw-text-lg {

        font-size: 1.125rem;

        line-height: 1.75rem
    }

    .md\:tw-text-xl {

        font-size: 1.25rem;

        line-height: 1.75rem
    }

    .md\:tw-leading-10 {

        line-height: 2.5rem
    }
}

@media (min-width: 1024px) {

    .lg\:tw--left-23\.5rem {

        left: -23.5rem
    }

    .lg\:tw-w-45w {

        width: 45%
    }

    .lg\:tw-w-48w {

        width: 48%
    }

    .lg\:tw-w-50w {

        width: 50%
    }

    .lg\:tw-w-55w {

        width: 55%
    }

    .lg\:tw-w-\[260px\] {

        width: 260px
    }

    .lg\:tw-w-\[300px\] {

        width: 300px
    }

    .lg\:tw-w-\[calc\(33\%-1rem\)\] {

        width: calc(33% - 1rem)
    }

    .lg\:tw-w-fit {

        width: fit-content
    }

    .lg\:tw-w-full {

        width: 100%
    }

    .lg\:tw-grid-cols-1 {

        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .lg\:tw-grid-cols-3 {

        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .lg\:tw-grid-cols-5 {

        grid-template-columns: repeat(5, minmax(0, 1fr))
    }

    .lg\:tw-flex-row {

        flex-direction: row
    }

    .lg\:tw-gap-10 {

        gap: 2.5rem
    }

    .lg\:tw-gap-12 {

        gap: 3rem
    }

    .lg\:tw-gap-3 {

        gap: 0.75rem
    }

    .lg\:tw-gap-5 {

        gap: 1.25rem
    }

    .lg\:tw-gap-6 {

        gap: 1.5rem
    }

    .lg\:tw-text-left {

        text-align: left
    }

    .lg\:tw-text-2xl {

        font-size: 1.5rem;

        line-height: 2rem
    }

    .lg\:tw-text-4xl {

        font-size: 2.25rem;

        line-height: 2.5rem
    }

    .lg\:tw-text-\[16px\] {

        font-size: 16px
    }

    .lg\:tw-text-\[18px\] {

        font-size: 18px
    }

    .lg\:tw-text-\[23px\] {

        font-size: 23px
    }

    .lg\:tw-text-lg {

        font-size: 1.125rem;

        line-height: 1.75rem
    }

    .lg\:tw-text-xl {

        font-size: 1.25rem;

        line-height: 1.75rem
    }

    .lg\:tw-leading-10 {

        line-height: 2.5rem
    }
}

